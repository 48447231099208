import React from "react"

import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Toolbar from "@mui/material/Toolbar"
import Stack from "@mui/system/Stack"
import { Menu, Settings } from "lucide-react"

import { useToggle } from "@hooks/useToggle"

import AnchorLink from "@components/AnchorLink"
import { IconWrapper, IconWrapperProps } from "@components/IconWrapper"
import DigitLogoLight from "@components/Logo/assets/logo-light.svg?react"
import UserMenu from "@views/Layout/UserMenu"
import SettingsModal from "@views/organizationSettings/SettingsModal"

import routes from "@constants/routes"

export interface TopNavigationProps {
  onToggleAppDrawer: () => void
  userMenu?: React.ReactNode
  digitLogo?: React.ReactNode
  settingsModal?: (params: {
    open: boolean
    toggleOpen: () => void
  }) => React.ReactNode
}

export const topNavigationHeight = 49

const iconColor: IconWrapperProps["color"] = (theme) => theme.palette.grey[200]

const iconSize = 20

export const TopNavigation: React.FC<TopNavigationProps> = ({
  onToggleAppDrawer,
  userMenu = <UserMenu />,
  digitLogo = <DigitLogoLight style={{ maxHeight: 20, maxWidth: 60 }} />,
  settingsModal = ({ open, toggleOpen }) => (
    <SettingsModal open={open} toggleOpen={toggleOpen} />
  ),
}) => {
  const [settingsModalOpen, toggleSettingsModalOpen] = useToggle({
    initialValue: false,
  })

  return (
    <AppBar
      position="fixed"
      variant="outlined"
      elevation={0}
      sx={{ zIndex: 3, border: 0, borderBottom: 1 }}
    >
      <Toolbar variant="dense" sx={{ zIndex: 3 }}>
        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack
            gap={1}
            alignItems="center"
            bgcolor="inherit"
            color="white"
            direction="row"
          >
            <IconButton
              aria-label="Toggle left sidebar menu"
              color="inherit"
              onClick={onToggleAppDrawer}
            >
              <IconWrapper size={iconSize} color={iconColor}>
                <Menu />
              </IconWrapper>
            </IconButton>

            <AnchorLink url={routes.DASHBOARD}>
              <Box display="flex" alignItems="center">
                {digitLogo}
              </Box>
            </AnchorLink>
          </Stack>

          <Stack gap={1} alignItems="center" direction="row" color="white">
            <IconButton
              onClick={toggleSettingsModalOpen}
              aria-label="Toggle settings menu"
            >
              <IconWrapper size={iconSize} color={iconColor}>
                <Settings />
              </IconWrapper>
            </IconButton>

            {userMenu}
          </Stack>
        </Stack>
      </Toolbar>

      {settingsModal({
        open: settingsModalOpen,
        toggleOpen: toggleSettingsModalOpen,
      })}
    </AppBar>
  )
}
