import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent"

// Populate using values from NerdGraph
const options = {
  info: {
    applicationID: import.meta.env.REACT_APP_NR_APPLICATION_ID,
    beacon: "bam.nr-data.net",
    errorBeacon: "bam.nr-data.net",
    licenseKey: import.meta.env.REACT_APP_NR_LICENSE_KEY,
    sa: 1,
  },
  init: {
    ajax: {
      deny_list: ["bam.nr-data.net"],
    },
    distributed_tracing: {
      enabled: true,
    },
    privacy: {
      cookies_enabled: true,
    },
    proxy: {
      beacon: import.meta.env.REACT_APP_NR_BEACON_PROXY,
      errorBeacon: import.meta.env.REACT_APP_NR_BEACON_PROXY,
    },
  },
  loader_config: {
    accountID: import.meta.env.REACT_APP_NR_ACCOUNT_ID,
    agentID: import.meta.env.REACT_APP_NR_APPLICATION_ID,
    applicationID: import.meta.env.REACT_APP_NR_APPLICATION_ID,
    licenseKey: import.meta.env.REACT_APP_NR_LICENSE_KEY,
    trustKey: import.meta.env.REACT_APP_NR_ACCOUNT_ID,
  },
}

const init = (): void => {
  if (import.meta.env.REACT_APP_NR_LICENSE_KEY) {
    // eslint-disable-next-line no-new
    new BrowserAgent(options)
  }
}

export default {
  init,
}
