import React, { useState, useEffect } from "react"

import MuiAvatar from "@mui/material/Avatar"
import Skeleton from "@mui/material/Skeleton"

import isImageLoading from "@services/isImageLoading"

import { colors } from "@constants/colors"

import DEFAULT_ITEM from "./images/default-item-icon.svg?url"
import DEFAULT_ORG from "./images/default-organization-icon.svg?url"
import DEFAULT_TOOL from "./images/default-tool-icon.svg?url"
import DEFAULT_USER from "./images/default-user-icon.svg?url"

export const COMPANY = "company"
export const ITEM = "item"
export const TOOL = "tool"

interface AvatarProps {
  image: string
  size: number

  alt?: string
  loading?: boolean
  sx?: any // eslint-disable-line @typescript-eslint/no-explicit-any
  type?: string
}

const Avatar: React.FC<AvatarProps> = ({
  alt,
  image,
  loading,
  size,
  sx,
  type,
}: AvatarProps) => {
  const [isLoading, setIsLoading] = useState(!!image)

  useEffect(() => {
    async function checkImageLoading() {
      const loadingStatus = await isImageLoading(image)
      setIsLoading(loadingStatus)
    }

    if (image) checkImageLoading()
  }, [image])

  if (loading || isLoading) {
    return (
      <Skeleton
        data-testid="Avatar"
        height={size}
        sx={sx}
        variant="rounded"
        width={size}
      />
    )
  }

  let imageSrc = image

  if (!image) {
    imageSrc = DEFAULT_USER
  }
  if (!image && type === COMPANY) {
    imageSrc = DEFAULT_ORG
  }
  if (!image && type === ITEM) {
    imageSrc = DEFAULT_ITEM
  }
  if (!image && type === TOOL) {
    imageSrc = DEFAULT_TOOL
  }

  return (
    <MuiAvatar
      alt={alt}
      data-testid="Avatar"
      src={imageSrc}
      sx={{
        border: `1px solid ${colors.BORDER_GRAY}`,
        height: `${size}px`,
        width: `${size}px`,
        ...sx,
      }}
      variant="rounded"
    />
  )
}

export default Avatar
