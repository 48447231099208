import React, { useCallback } from "react"

import ListItemButton from "@mui/material/ListItemButton"
import Typography from "@mui/material/Typography"
import Box from "@mui/system/Box"
import Stack from "@mui/system/Stack"
import { CornerDownRight } from "lucide-react"
import { useTranslation } from "react-i18next"

import { useToggle } from "@hooks/useToggle"

import AnchorLink from "@components/AnchorLink"
import { IconWrapper } from "@components/IconWrapper"
import { lightVsDarkStyles } from "@helpers/lightVsDarkStyles"

import ls from "@services/localStorage"

import { SubMenuItemWithLink } from "../SubMenuItemWithLink"
import {
  subMenuItemHoverArrowIconColor,
  subMenuItemIconColor,
  subMenuItemIconSize,
} from "../subMenuItemUtils"
import { SubMenuItemBase } from "../types"

export interface SubMenuItemWithChildren extends SubMenuItemBase {
  items: Omit<SubMenuItemWithLink, "icon">[]
}

export const SubMenuItemWithChildren: React.FC<SubMenuItemWithChildren> = ({
  icon,
  translatedTextKey,
  items,
}) => {
  const { t } = useTranslation()

  const localStorageKey = `AppMenu-sub-menu-${translatedTextKey}-open`

  const onToggleCallback = useCallback(
    (isOpen: boolean) => {
      ls.setItem(localStorageKey, isOpen)
    },
    [localStorageKey],
  )

  const [open, toggleOpen] = useToggle({
    initialValue: Boolean(ls.getItem(localStorageKey)),
    onToggle: onToggleCallback,
  })

  const [hoveredTranslationKey, setHoveredTranslationKey] = React.useState<
    string | null
  >(null)

  const onMouseEnter = useCallback((translatedTextKeyForHoverState: string) => {
    setHoveredTranslationKey(translatedTextKeyForHoverState)
  }, [])

  const onMouseLeave = useCallback(() => {
    setHoveredTranslationKey(null)
  }, [])

  return (
    <Stack direction="column" gap={0.5}>
      <ListItemButton
        onClick={toggleOpen}
        sx={{ pl: 1, py: 0.5, mr: 1, borderRadius: 0.5 }}
      >
        <Stack direction="row" gap={1} alignItems="center">
          {icon && (
            <IconWrapper
              color={subMenuItemIconColor}
              size={subMenuItemIconSize}
            >
              {icon}
            </IconWrapper>
          )}

          <Typography variant="h6" fontWeight="500">
            {t(translatedTextKey)}
          </Typography>
        </Stack>
      </ListItemButton>

      {open && (
        <Stack direction="column" gap={0.5}>
          {items.map(({ translatedTextKey: childTranslatedTextKey, route }) => {
            return (
              <AnchorLink url={route} key={childTranslatedTextKey}>
                <ListItemButton
                  sx={{
                    pl: 2,
                    py: 0.5,
                    mr: 1,
                    borderRadius: 0.5,
                    position: "relative",
                  }}
                  onMouseEnter={() => onMouseEnter(childTranslatedTextKey)}
                  onMouseLeave={onMouseLeave}
                >
                  {hoveredTranslationKey === childTranslatedTextKey && (
                    <Box
                      sx={{
                        transform: "translate(-3px, -2px)",
                        position: "absolute",
                      }}
                    >
                      <IconWrapper
                        size={subMenuItemIconSize}
                        color={subMenuItemHoverArrowIconColor}
                      >
                        <CornerDownRight />
                      </IconWrapper>
                    </Box>
                  )}

                  <Typography
                    variant="h6"
                    fontWeight="500"
                    sx={lightVsDarkStyles({
                      defaultStyles: { pl: 2 },
                      lightStyles: { color: "grey.600" },
                      darkStyles: { color: "grey.400" },
                    })}
                  >
                    {t(childTranslatedTextKey)}
                  </Typography>
                </ListItemButton>
              </AnchorLink>
            )
          })}
        </Stack>
      )}
    </Stack>
  )
}
