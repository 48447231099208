export const LB_UOM_ID =
  import.meta.env.REACT_APP_ENV === "production"
    ? "018ba726-6c93-79d9-985c-7e607a2dc013"
    : "018ba48a-97b5-70c1-88f8-6a4a6fd0eb23"

export const roles = {
  FULL_ACCESS_USER: "Full Access User",
  SHOP_FLOOR_OPERATOR: "Shop Floor Operator",
}

export const userStatuses = {
  ACTIVE: "active",
  PENDING: "pending",
  SUSPENDED: "suspended",
}

export const sorts = {
  ASC: "asc",
  DESC: "desc",
}

export const pag = {
  PREV: "prev",
  NEXT: "next",
}

export const companyTypes = {
  CUSTOMER: "customer",
  VENDOR: "vendor",
}

export const companyStatuses = {
  ACTIVE: "active",
  ARCHIVED: "archived",
}

export const addressTypes = {
  BILLING: "billing",
  SHIPPING: "shipping",
}

export const contactTypes = {
  CUSTOMER: "customer",
  MARKETING: "marketing",
  SALES: "sales",
  VENDOR: "vendor",
}

export const poStatuses = {
  APPROVED: "approved",
  ARCHIVED: "archived",
  CANCELLED: "cancelled",
  CLOSED: "closed",
  DRAFT: "draft",
  OPEN: "open",
  REJECTED: "rejected",
  SENT: "sent",
}

export const shippingCarriers = {
  OTHER: "Other",
  ON_SITE: "On-site pickup",
}

export const operationStatuses = {
  ACTIVE: "active",
  ARCHIVED: "archived",
}

export const orderStatuses = {
  DRAFT: "draft",
  UNFULFILLED: "unfulfilled",
  PARTIALLY_FULFILLED: "partially_fulfilled",
  FULFILLED: "fulfilled",
  CANCELLED: "cancelled",
  RETURNED: "returned",
  REFUNDED: "refunded",
  CLOSED: "closed",
}

export const NOT_PAID = "NOT_PAID"
export const paymentStatuses = {
  PAID_IN_FULL: "paid_in_full",
  NOT_PAID: "not_paid",
  PARTIALLY_PAID: "partially_paid",
  PAYMENT_PENDING: "payment_pending",
  OVERDUE: "overdue",
  DISPUTED: "disputed",
  REFUNDED: "refunded",
  CANCELLED: "cancelled",
}

export const itemTypes = {
  BUY: "buy",
  MAKE: "make",
  SELL: "sell",
}

export const warehouseLocationStatuses = {
  ACTIVE: "active",
  ARCHIVED: "archived",
}

export const receivingStatuses = {
  AWAITING_SHIPMENT: "awaiting_shipment",
  SHIPPED: "shipped",
  RECEIVING_IN_PROGRESS: "receiving_in_progress",
  RECEIVED: "received",
}

// confusingly named, these are inventoryStatuses
export const receivingItemStatuses: {
  [key: string]: string
} = {
  ACTIVE: "active",
  ARCHIVED: "archived",
  DEPLETED: "depleted",
  QUARANTINED: "quarantined",
  SOLD: "sold",
  TRASHED: "trashed",
}

export const storageTypes = {
  SINGLE: "single",
  NESTED: "nested",
}

export const jobTypes = {
  PRODUCTION: "PRODUCTION",
  CONVERSION: "CONVERSION",
  QUEUE_CONVERSION: "QUEUE_CONVERSION",
  // SORTING: "SORTING"
}

export const jobPackingTypes = {
  NO_PACKING: "NO_PACKING",
  STANDARD: "STANDARD_PACKING",
  NESTED: "NESTED_PACKING",
}

export const jobPriority = {
  RUSH: "RUSH",
  NORMAL: "NORMAL",
}

export const jobStatus = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
}

export const jobQCStatus = {
  PASS: "pass",
  FAIL: "fail",
}

export const shippingContainerTypes = {
  BOX: "package",
  PALLET: "pallet",
  NONE: "asIs",
}

export const uomUnitIdenfiers = {
  AREA: "area",
  COUNT: "count",
  LENGTH: "length",
  MASS: "mass",
  VOLUME: "volume",
}

export const weightUnits = {
  LB: "lb",
}

export const shippingStatuses: {
  [key: string]: string
} = {
  AWAITING_CARRIER: "awaiting_carrier",
  AWAITING_DROP_OFF: "awaiting_drop_off",
  AWAITING_PICK_UP: "awaiting_pickup",
  SHIPPED: "shipped",
}

export const shipmentTypes = {
  CARRIER: "carrier",
  PICK_UP: "pick_up",
  DROP_OFF: "drop_off",
}

export const shipmentPickingStatuses = {
  NOT_STARTED: "not_started",
  PARTIALLY_PICKED: "partially_picked",
  FULLY_PICKED: "fully_picked",
}

export const viewModes = {
  DARK: "dark",
  LIGHT: "light",
}

export const customFieldTypes = {
  TEXT: "text",
  SINGLE_SELECT: "singleSelect",
  MULTI_SELECT: "multiSelect",
  DATE: "date",
  NUMBER: "number",
  IDENTIFIER: "identifier",
}

export const customFieldContexts = {
  ADDRESS: "address",
  COMPANY: "company",
  CONTACT: "contact",
  INVENTORY: "inventory",
  ITEM: "item",
  JOB: "job",
  ORDER: "order",
  ORG_MEMBER: "organizationMember",
  ORG: "organization",
  PO: "purchaseOrder",
  QUOTE: "quote",
  TOOL: "tool",
  TOOL_SETUP_CONFIG: "toolSetupConfiguration",
  WAREHOUSE_LOCATION: "warehouseLocation",
}

export const labelTypes = {
  RECEIVING: "receiving",
  PRODUCTION: "production",
  MANUAL_INVENTORY: "manualInventory",
}

export const customLabelLogoTypes = {
  ORG: "organization",
  VENDOR: "vendor",
  CUSTOMER: "customer",
}

export enum RutterConnectionStatus {
  Disabled = "disabled",
  NotReady = "notReady",
  Ready = "ready",
}

export const createdThroughTypes = {
  JOB: "job",
}

export const FOB = {
  SHIPPER: "shipper",
  CONSIGNEE: "consignee",
}

export const LINE_ITEM_LAYOUT_CONFIG = {
  ITEM: "Item",
  ITEM_NAME: "Item name",
  ITEM_DESCRIPTION: "Item description",
  INTERNAL_SKU: "Internal SKU",
  ITEM_NOTES: "Notes",
  VENDOR_SKU: "Vendor SKU",
  QUANTITY: "Quantity",
  UNIT_OF_MEASURE: "Unit of measure",
  UNIT_COST: "Unit cost",
  TOTAL: "Total",
  DELIVER_BY: "Deliver by",
}

export const quoteStatuses = {
  DRAFT: "draft",
  SENT: "sent",
  REVISIONS_NEEDED: "revisionsNeeded",
  EXPIRED: "expired",
  CLOSED_WON: "closedWon",
  CLOSED_LOST: "closedLost",
}

export const toolStatuses: {
  [key: string]: string
} = {
  ACTIVE: "active",
  ARCHIVED: "archived",
}
