import pino, { Level } from "pino"

import { isProductionEnv } from "@helpers/isProductionEnv"

export const getLogLevel = ({
  isProductionEnvFn = isProductionEnv,
  localStorageGetFn = (key: string) => localStorage.getItem(key),
}: {
  isProductionEnvFn?: () => boolean
  localStorageGetFn?: typeof localStorage.getItem
}): Level => {
  if (isProductionEnvFn()) {
    return "error"
  }

  const localStorageLogLevel = localStorageGetFn("LOG_LEVEL")

  if (localStorageLogLevel && localStorageLogLevel in pino.levels.values) {
    return localStorageLogLevel as Level
  }

  return "debug"
}

export const logger = pino({ level: getLogLevel({}) })
