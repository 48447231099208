import React from "react"

import MuiAppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import Stack from "@mui/system/Stack"

import { useAppDrawer } from "@hooks/useAppDrawer"

import { useThemeContext } from "@providers/AppThemeProvider"

import { colors } from "@constants/colors"

import { appDrawerWidth } from "./AppDrawer"
import TabsRouter from "./TabsRouter"
import { topNavigationHeight } from "./TopNavigation"

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "light" ? colors.STANDARD_WHITE : "none",
  boxShadow: "none",
  color:
    theme.palette.mode === "light"
      ? theme.palette.grey[900]
      : theme.palette.grey[100],
  paddingTop: 8,
}))

interface AppbarProps {
  action?: React.ReactNode
  disabled?: boolean
  hub?: string
  favoriteFeature?: string
}

export const appBarHeight = 93

const Appbar: React.FC<AppbarProps> = ({
  action,
  disabled,
  hub,
  favoriteFeature,
}: AppbarProps) => {
  const { open } = useAppDrawer()
  const { darkMode } = useThemeContext()

  return (
    <AppBar
      sx={{
        // has to match color of background Stack in UnprotectedLayout
        backgroundColor: darkMode
          ? colors.BG_DARK_GRAY_2
          : colors.BG_LIGHT_GRAY_4,
        border: 0,
        left: open ? appDrawerWidth : 0,
        py: 2,
        top: `${topNavigationHeight}px`,
        zIndex: 3,
      }}
      variant="outlined"
      elevation={0}
    >
      <Toolbar variant="dense">
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            width: open ? `calc(100% - ${appDrawerWidth}px)` : "100%",
          }}
        >
          <Stack direction="column" gap={1}>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              {hub}
            </Typography>

            {!disabled && (
              <TabsRouter hub={hub} favoriteFeature={favoriteFeature} />
            )}
          </Stack>

          {action && <Box>{action}</Box>}
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Appbar
