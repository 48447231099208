import { logger } from "@helpers/logger"

// store all local storage keys here
export const localKeys = {
  DRAWER: "digit-drawer-state",
  VIEW_MODE: "digit-selected-viewmode",
  LANGUAGE: "digit-selected-language",
  PICK_MODE: "digit-pick-mode",
  TARE_MODE: "digit-tare-mode",
}

function setLocalStorageItem(key: string, value: string | boolean): void {
  try {
    const serializedValue = JSON.stringify(value)
    localStorage.setItem(key, serializedValue)
  } catch (error) {
    logger.error("Error storing data in localStorage:", error)
  }
}

function getLocalStorageItem<T>(key: string): T | null {
  try {
    const serializedValue = localStorage.getItem(key)
    if (serializedValue === null) {
      return null
    }
    return JSON.parse(serializedValue)
  } catch (error) {
    logger.error("Error retrieving data from localStorage:", error)
    return null
  }
}

const ls = {
  setItem: setLocalStorageItem,
  getItem: getLocalStorageItem,
}

export default ls
