import React from "react"

import { type Theme } from "@mui/system"
import Box from "@mui/system/Box"

import { useThemeContext } from "@providers/AppThemeProvider"

import { SxType } from "@gql/sharedTypes"

export interface IconWrapperProps {
  /** Icon to display */
  children: React.ReactElement

  color?: (theme: Theme) => string
  /** Size in pixels, sets width and height */
  size?: number
  sx?: SxType
}

/**
 * Wrapper around a IconWrapper with a color and size
 *
 * @example <IconWrapper color={(theme) => theme.palette.primary.main} size={16}><HomeIcon /></IconWrapper>
 * */

export const IconWrapper: React.FC<IconWrapperProps> = ({
  children,
  size = 15,
  color,
  sx,
}) => {
  const { darkMode } = useThemeContext()

  const defaultColor = (theme: Theme) =>
    darkMode ? theme.palette.grey[400] : theme.palette.grey[700]

  return (
    <Box
      sx={(theme) => ({
        color: color ? color(theme) : defaultColor(theme),
        alignItems: "center",
        display: "flex",
        ...sx,
      })}
    >
      {React.Children.map(children, (child) => ({
        ...child,
        props: {
          ...child.props,
          color: "currentColor",
          width: size,
          height: size,
        },
      }))}
    </Box>
  )
}
